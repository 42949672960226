import React from 'react';
import styled, { withTheme } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import moment from 'moment';
import Img from 'gatsby-image';
import { get, uniqBy, orderBy, filter, truncate } from 'lodash';
import { Row, Col } from 'antd';
import {
  Title,
  Text,
  Link,
  Paragraph,
  FlowerPattern,
  BodyImageSection,
} from '../common/components';
import NewsBodyImage from './NewsBodyImage';

import flowerGold1 from '../../assets/flower-3-gold.svg';
import flowerGold2 from '../../assets/flower-2-gold.svg';

const Container = styled.div`
  position: relative;
  margin-bottom: 3rem;
`;

const ArticlesRow = styled(Row)``;

interface Props {
  data: any;
  displayFilters: boolean;
  theme: any;
}

class NewsArticles extends React.Component<Props, any> {
  renderArticles = () => {
    const { data } = this.props;
    const articles = get(data, 'items', []);

    const orderedByMostRecentArticles = orderBy(
      articles,
      'article.document[0].data.creation_date',
      'desc',
    );
    return orderedByMostRecentArticles.map((article, idx) => {
      return <NewsBodyImage
        title={get(article, 'article.document[0].data.title', '')}
        description={truncate(get(article, 'article.document[0].data.content.text', ''), {
          length: 200,
        })}
        image={get(article, 'article.document[0].data.preview_image')}
        readMoreText={get(article, 'article.document[0].data.read_more_text', 'Read more')}
        readMoreUrl={get(article, 'article.document[0].data.url', '')}
        displayImagePosition={get(article, 'preview_image_position', 'left')}
      />
      // return (
      //   <BodyImageSection
      //     title={get(article, 'article.document[0].data.title', '')}
      //     description={truncate(get(article, 'article.document[0].data.content.text', ''), {
      //       length: 200,
      //     })}
      //     image={get(article, 'article.document[0].data.preview_image')}
      //     readMoreText={get(article, 'article.document[0].data.read_more_text', 'Read more')}
      //     readMoreUrl={get(article, 'article.document[0].data.url', '')}
      //     displayImagePosition={'right'}
      //   />
      // );
    });
  };

  render() {
    const { data = null } = this.props;
    if (!data) return null;

    return (
      <div>
        {/* <FlowerPattern
          src={flowerGold1}
          height={'100px'}
          width={'auto'}
          right={'96%'}
          bottom={'0'}
          opacity={0.6}
        />
        <FlowerPattern
          src={flowerGold2}
          height={'140px'}
          width={'auto'}
          right={'10px'}
          bottom={'80%'}
          opacity={0.6}
        /> */}

        <div>
          {/* <Col xs={22} offset={1}> */}
          {this.renderArticles()}
          {/* </Col> */}
        </div>
      </div>
    );
  }
}

export default withTheme(NewsArticles);
