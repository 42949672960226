import { graphql } from 'gatsby';

import React, { Component } from 'react';
import { get, find } from 'lodash';

import Layout from '../layout/Layout';

import { PageHero, Introduction } from '../common/components/';

import NewsArticles from './NewsArticles';

interface Props {
  data: any;
  location: any;
}

class News extends Component<Props, any> {
  render() {
    const { data } = this.props;

    const newsTitle = get(data, 'page.data.title', null);
    const newsDescription = get(data, 'page.data.description', '');
    const body = get(data, 'page.data.body');
    const heroData = find(body, { __typename: 'PrismicNewsBodyHero' });
    const articlesData = find(body, {
      __typename: 'PrismicNewsBodyNews',
    });

    return (
      <Layout data={data} location={this.props.location}>
        {heroData && <PageHero data={heroData} />}
        {newsTitle && (
          <Introduction
            title={newsTitle.toUpperCase()}
            description={newsDescription}
            height={newsDescription ? 200 : 1}
            backgrounds={newsDescription ? null : [null]}
          />
        )}
        {articlesData && (
          <NewsArticles data={articlesData} displayFilters={false} />
        )}
      </Layout>
    );
  }
}

export default News;

export const query = graphql`
  query NewsQuery($slug: String!) {
    page: prismicNews(id: { eq: $slug }) {
      ...News
    }
  }
`;
