import React, { Component } from 'react';
import { Row, Col } from 'antd';
import styled, { withTheme } from 'styled-components';
import {
    Title,
    Paragraph,
    SectionContainer,
    Image,
    Link,
} from '../common/components';
import breakpoint from 'styled-components-breakpoint';

interface Props {
    title: string;
    description: any;
    image: any;
    readMoreUrl?: string;
    readMoreText?: string;
    readMoreLinkTarget?: string;
    theme: any;
    displayImagePosition: 'right' | 'left'
}

const Container = styled(SectionContainer)`
  position: relative;
  min-height: 250px;
  margin-bottom: 70px !important;
  margin-top: 0px !important;
  padding-bottom: 0;
  padding: 0 5%;

  ${breakpoint('md')`
    padding-bottom: 30px;
  `}
`;

const TitleText = styled(Title)`
  text-align: left;
  text-transform: uppercase;
`;

const ImageCol = styled(Col)`
  overflow: hidden;
`;

const Img = styled(Image)``;

const ParagraphContext = styled(Paragraph)`
  text-align: left;
`;

const DescriptionCol = styled(Col)`
  padding: 20px 0 !important;

  ${breakpoint('md')`
    padding-left: 20px !important;
  `}

  ${breakpoint('lg')`
      padding: 0 20px !important;
      padding-left: 100px !important;
  `}
`;

const ReadMoreLinkText = styled(Paragraph)`
  text-align: left;
  font-weight: bold !important;
`;

class NewsBodyImage extends Component<Props, any> {
    render() {
        const title = this.props.title;
        const descriptionHtml = this.props.description;
        const image = this.props.image;
        const readMoreText = this.props.readMoreText;
        const readMoreUrl = this.props.readMoreUrl;
        const readMoreLinkTarget = this.props.readMoreLinkTarget;
        const displayImagePosition = this.props.displayImagePosition;

        return <Container>
            <Row type="flex" justify="center" align="middle">
                {/* Name Service Limousine */}
                <Col xs={24} md={0}>
                    {title && <TitleText>{title}</TitleText>}
                </Col>
                {/* Golf Image Left */}
                {displayImagePosition === 'left' ? (<ImageCol xs={24} md={{ span: 11 }}>
                    <Img data={image} width="100%" />
                </ImageCol>
                ) : null}

                {/* Context */}
                <DescriptionCol xs={24} md={10}>
                    <Row type="flex" justify="start">
                        <Col xs={0} md={24}>
                            {title && (
                                <TitleText level={2} size={1}>
                                    {title}
                                </TitleText>
                            )}
                        </Col>
                        <Col>
                            {descriptionHtml && (
                                <ParagraphContext small>
                                    <span
                                        dangerouslySetInnerHTML={{ __html: descriptionHtml }}
                                    />
                                </ParagraphContext>
                            )}
                            {readMoreText && (
                                <Link to={readMoreUrl} target={readMoreLinkTarget}>
                                    <ReadMoreLinkText
                                        small
                                        color={this.props.theme.color.text.main}
                                    >
                                        {readMoreText}
                                    </ReadMoreLinkText>
                                </Link>
                            )}
                        </Col>
                    </Row>
                </DescriptionCol>
                {/* Golf Image Right */}
                {displayImagePosition === 'right' ? (<ImageCol xs={24} md={{ span: 11, offset: 3 }}>
                    <Img data={image} width="100%" />
                </ImageCol>
                ) : null}
            </Row>
        </Container>
    }
}

export default withTheme(NewsBodyImage);